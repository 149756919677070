


































import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import { useMutations } from '@u3u/vue-hooks'

import { gsap } from 'gsap'
import { Picture } from '@/inc/types'

import UiPicture from '@/components/ui/Picture.vue'

interface HomeOrganizersHero {
  displayTitle: string
  htmltext: string
  picture: Picture
  title: string
  video?: {
    youtubeId: string
  }
}

export default defineComponent({
  name: 'HomeOrganizersHero',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<HomeOrganizersHero>,
      required: true,
    },
  },

  setup(props, ctx) {
    const { SET_VIDEO: setVideo } = useMutations('ui', ['SET_VIDEO'])
    const root = ref()

    const openVideo = () => {
      ctx.emit('video', props.content.video?.youtubeId)
      setVideo(props.content.video?.youtubeId)
    }

    onMounted(() => {
      const video = root.value.querySelector('.video')
      const picture = root.value.querySelector('.picture')
      const container = video ? video : picture
      const button = root.value.querySelector('.button')

      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([container, button], { clearProps: 'all' })
        },
      })

      tl.from(container, { y: 50, opacity: 0 })

      tl.from(button, {
        opacity: 0,
        scale: 2,
        ease: 'power4.out',
        duration: 0.5,
      })
    })

    return {
      root,
      openVideo,
    }
  },
})
