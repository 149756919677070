































import type { Link, Picture } from '@/inc/types'
import { PropType, defineComponent } from '@vue/composition-api'
import UiPicture from '@/components/ui/Picture.vue'

interface HomeOrganizersCta {
  headline: string
  title: string
  link: Link
  picture: Picture
  alternate?: boolean
}

export default defineComponent({
  name: 'HomeOrganizersCta',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<HomeOrganizersCta>,
      required: true,
    },
  },
})
